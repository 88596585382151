import {Image} from '@wix/ambassador-ecom-v1-checkout/types';

export class ItemMediaModel {
  public altText: string | null;
  public height?: number;
  public width?: number;
  public src: string;

  constructor({height, width, id, url, altText}: Image, productName: string) {
    this.height = height;
    this.width = width;
    this.src = id ?? url!;
    this.altText = altText || productName;
  }
}
