import {errorInCheckoutParams} from '@wix/bi-logger-ec-site/v2/types';
import {CheckoutModel} from '../models/Checkout.model';
import {ItemTypePreset} from '../models/ItemType.model';
import {CheckoutSettingsModel} from '../models/checkoutSettings/CheckoutSettings.model';
import {CheckoutErrorCode} from './errors';
import {CartType} from '@wix/wixstores-client-core';
import {NavigationService} from '../services/NavigationService';

export const BI_AMOUNT_MULTIPLIER = 100000;

export const toBiAmount = (amount: number) => Math.round(amount * BI_AMOUNT_MULTIPLIER);

export enum CheckoutOrigin {
  checkout = 'checkout',
}

export enum MethodType {
  pickup = 'pickup',
  nonPickup = 'non-pickup',
}

export enum FlowType {
  fastFlow = 'fast flow',
  visitorPickupFlow = 'visitor pickup flow',
  memberPickupFlow = 'member pickup flow',
  visitorShippingFlow = 'visitor shipping flow',
  memberShippingFlow = 'member shipping flow',
  nonShippableFlow = 'non shippable flow',
  unrecognizedFlow = 'unrecognized Flow',
}

export enum StepName {
  PLACE_ORDER_FAST_FLOW = 'PlaceOrderFastFlow',
  SHIPPING_DETAILS = 'ShippingDetails',
  ONLOAD_OR_UPDATE_FAST_FLOW = 'OnLoadOrUpdateFastFlow',
}

export const ECOM_PLATFORM_CHECKOUT = 'ecom-platform-checkout';

export const cantShipToDestinationBaseParams = (): Partial<errorInCheckoutParams> => {
  return {
    stage: StepName.SHIPPING_DETAILS,
    field: 'canShipToDestination',
    errorMessage: 'cannot ship to destination',
  };
};

export const getCatalogAppIds = (checkout: CheckoutModel): string => {
  return getMappedUniqueList(checkout.lineItems, ({catalogAppId}) => catalogAppId);
};

export const getCustomFieldsTitles = (checkout: CheckoutModel): string | undefined => {
  return checkout.customField?.title;
};

export const isCustomFieldMandatory = (checkoutSettings: CheckoutSettingsModel): boolean => {
  return checkoutSettings.customField.mandatory;
};

export const getItemTypes = (checkout: CheckoutModel): string => {
  return getMappedUniqueList(checkout.lineItems, ({itemType}) =>
    itemType?.preset ? itemType?.preset.toString() : itemType?.custom
  );
};

export const getCouponErrorCodeForBi = (code: string): string => {
  switch (code) {
    case CheckoutErrorCode.ERROR_INVALID_COMMAND_FIELD:
      return 'ERROR_INVALID_COMMAND_FIELD_FOR_COUPON';
    case CheckoutErrorCode.ERROR_INVALID_SUBTOTAL:
      return 'ERROR_INVALID_SUBTOTAL_FOR_COUPON';
    case CheckoutErrorCode.ERROR_INVALID_PRODUCTS:
      return 'ERROR_INVALID_PRODUCTS_FOR_COUPON';
    case CheckoutErrorCode.ERROR_INVALID_PRODUCT_QUANTITY:
      return 'ERROR_INVALID_PRODUCT_QUANTITY_FOR_COUPON';
    case CheckoutErrorCode.ERROR_NOT_APPLICABLE_FOR_SUBSCRIPTIONS:
      return 'ERROR_NOT_APPLICABLE_FOR_SUBSCRIPTIONS_FOR_COUPON';
    default:
      return code;
  }
};

export const getShippingOptionTypes = (checkout: CheckoutModel): string => {
  return getMappedUniqueList([...checkout.shippingOptions, ...checkout.pickupOptions], ({title}) => title);
};

export const getSelectedShippingOptionIndex = (checkout: CheckoutModel): number | undefined => {
  const isSelectedOption = ({code}: {code: string}) => code === checkout.selectedShippingOption?.code;

  const shippingOptionIndex = checkout.shippingOptions.findIndex(
    (option) =>
      isSelectedOption(option) || /* istanbul ignore next */ option.scheduledDeliveryOptions?.some(isSelectedOption)
  );

  if (shippingOptionIndex !== -1) {
    return shippingOptionIndex;
  }

  const pickupOptionIndex = checkout.pickupOptions.findIndex(isSelectedOption);

  return pickupOptionIndex !== -1 ? pickupOptionIndex + checkout.shippingOptions.length : /* istanbul ignore next */ -1;
};

export const hasPickupOption = (checkout: CheckoutModel): boolean => {
  return checkout.pickupOptions.length > 0;
};

export const isFullyPaidByGiftCard = (checkout: CheckoutModel): boolean => {
  return Boolean(
    checkout.giftCard?.price?.amount &&
      checkout.giftCard?.price?.amount > 0 &&
      checkout.payNowTotalAfterGiftCard.amount === 0
  );
};

function getMappedUniqueList<T>(arr: T[] | undefined, mapper: (t: T) => string | undefined): string {
  return getUniques((arr ?? /* istanbul ignore next */ []).map(mapper).filter(isDefined) as string[]).toString();
}

export const getAdditionalFeesPrice = (checkout: CheckoutModel): number => {
  return toBiAmount(checkout.priceSummary?.additionalFees.amount ?? /* istanbul ignore next */ 0);
};

export const getNumberOfAdditionalFees = (checkout: CheckoutModel): number => {
  return checkout.additionalFees?.length ?? /* istanbul ignore next */ 0;
};

export const getCartType = ({lineItems}: CheckoutModel): CartType => {
  const hasDigitalItems = lineItems?.some((lineItem) => lineItem.itemType?.preset === ItemTypePreset.DIGITAL);
  const hasPhysicalItems = lineItems?.some((lineItem) => lineItem.itemType?.preset === ItemTypePreset.PHYSICAL);
  const hasServiceItems = lineItems?.some((lineItem) => lineItem.itemType?.preset === ItemTypePreset.SERVICE);
  const hasGiftCardItems = lineItems?.some((lineItem) => lineItem.itemType?.preset === ItemTypePreset.GIFT_CARD);

  if (hasDigitalItems && hasPhysicalItems) {
    return CartType.MIXED;
  }

  if (hasDigitalItems) {
    return CartType.DIGITAL;
  }

  if (hasPhysicalItems) {
    return CartType.PHYSICAL;
  }

  if (hasServiceItems) {
    return CartType.SERVICE;
  }

  if (hasGiftCardItems) {
    return CartType.GIFT_CARD;
  }

  return CartType.UNRECOGNISED;
};

const getUniques = (arr: string[]): string[] => {
  return [...new Set([...arr])];
};

const isDefined = <T>(obj: T): boolean => {
  return !!obj;
};

export const getFlowType = (navigationService: NavigationService): FlowType => {
  /* istanbul ignore else*/
  if (navigationService.isFastFlow) {
    return FlowType.fastFlow;
  } else if (navigationService.isVisitorPickupFlow) {
    return FlowType.visitorPickupFlow;
  } else if (navigationService.isMemberPickupFlow) {
    return FlowType.memberPickupFlow;
  } else {
    return FlowType.unrecognizedFlow;
  }
};
