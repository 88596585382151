export type StepImplementationProps = {
  index?: number;
};

export type ActiveStep = {
  stepId: StepId | null;
  stepIndex: number;
};

export enum StepId {
  customerDetails = 'contact-details',
  deliveryMethod = 'delivery-method',
  payment = 'payment-and-billing',
  placeOrder = 'place-order',
}
