import {StreetAddress} from '@wix/ambassador-ecom-v1-checkout/types';

export class StreetAddressModel {
  public name?: string;
  public number?: string;
  constructor(streetAddress: StreetAddress) {
    this.name = streetAddress.name;
    this.number = streetAddress.number;
  }
}
