import {ShippingOption} from '@wix/ambassador-ecom-v1-checkout/types';

export class ScheduledDeliveryOptionModel {
  public code: string;
  public deliveryTime: string;
  constructor(option: ShippingOption) {
    this.code = option.code!;
    this.deliveryTime = option.logistics?.deliveryTime ?? '';
  }
}
