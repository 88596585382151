import {GiftCard} from '@wix/ambassador-ecom-v1-checkout/types';
import {PriceModel} from './Price.model';

export class GiftCardModel {
  public price: PriceModel;
  public id: string;
  public obfuscatedCode: string;

  constructor(giftCard: GiftCard) {
    this.price = new PriceModel(giftCard.amount);
    this.id = giftCard.id!;
    this.obfuscatedCode = giftCard.obfuscatedCode!;
  }
}
