import {IWidgetControllerConfig} from '@wix/native-components-infra';
import {createExpressCheckoutButtonsController} from '@wix/ecom-platform-express-checkout-buttons';
import {NavigationService} from '../services/NavigationService';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {SPECS} from '../../components/Checkout/constants';
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class ExpressCheckoutStore {
  constructor({
    controllerConfig,
    navigationService,
    siteStore,
    flowAPI,
  }: {
    controllerConfig: IWidgetControllerConfig;
    navigationService: NavigationService;
    siteStore: SiteStore;
    flowAPI: ControllerFlowAPI;
  }) {
    if (
      !(
        siteStore.experiments.enabled(SPECS.UseNewCheckoutInVisitorPickup) ||
        siteStore.experiments.enabled(SPECS.UseNewCheckoutInMemberPickupFlow)
      )
    ) {
      return;
    }

    createExpressCheckoutButtonsController({
      currency: navigationService.currency ?? siteStore.currency,
      controllerConfig,
      httpClient: flowAPI.essentials.httpClient,
    });
  }
}
