import {PriceSummary} from '@wix/ambassador-ecom-v1-checkout/types';
import {PriceModel} from './Price.model';

export class PriceSummaryModel {
  public shipping: PriceModel;
  public subtotal: PriceModel;
  public total: PriceModel;
  public tax: PriceModel;
  public additionalFees: PriceModel;
  public discount: PriceModel;
  constructor(priceSummary?: PriceSummary) {
    this.shipping = new PriceModel(priceSummary?.shipping);
    this.subtotal = new PriceModel(priceSummary?.subtotal);
    this.total = new PriceModel(priceSummary?.total);
    this.tax = new PriceModel(priceSummary?.tax);
    this.additionalFees = new PriceModel(priceSummary?.additionalFees);
    this.discount = new PriceModel(priceSummary?.discount);
  }
}
