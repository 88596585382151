import {HttpError} from '@wix/http-client';
import {CheckoutErrorCode, CouponErrorCodes, ErrorType, GiftCardErrorCodes, PaymentErrorCode} from '../utils/errors';
import {ApplicationError, Details} from '@wix/ambassador-ecom-v1-checkout/types';

export interface ErrorData {
  message: string;
  details: Details;
}

export class CheckoutErrorModel {
  public type: string;
  public code: string;
  public data?: Record<string, any> | null;

  public static fromHttpError(httpError: HttpError): CheckoutErrorModel {
    const errorDataDetails = (httpError?.response?.data as ErrorData)?.details;
    return new CheckoutErrorModel(convertToError(errorDataDetails));
  }

  constructor({code, data, type}: {code: string; data?: Record<string, any> | null; type: string}) {
    this.code = code;
    this.data = data;
    this.type = type;
  }
}

function convertToError(errorDataDetails: Details): {code: string; data: Record<string, any> | null; type: string} {
  if (isMinimumOrderAmountError(errorDataDetails)) {
    const {code, data} = (errorDataDetails.applicationError!.data!.orderValidationErrors as ApplicationError[])[0];
    return {
      type: ErrorType.MINIMUM_ORDER_AMOUNT,
      code: code!,
      data: data as Record<string, any>,
    };
  }

  if (isGiftCardError(errorDataDetails)) {
    const giftCardCalculationError = errorDataDetails?.applicationError?.data?.giftCardCalculationError as Details;
    const errorDetails = giftCardCalculationError ?? errorDataDetails;
    return getGiftCardError(errorDetails);
  }

  if (isCouponError(errorDataDetails)) {
    const couponCalculationError = errorDataDetails?.applicationError?.data?.couponCalculationError as Details;
    const errorDetails = couponCalculationError ?? errorDataDetails;
    return getCouponError(errorDetails);
  }

  const code = CheckoutErrorCode[errorDataDetails?.applicationError?.code as string] ?? CheckoutErrorCode.GENERAL_ERROR;
  const data =
    (errorDataDetails?.applicationError?.data as Record<string, any>) ??
    (errorDataDetails?.validationError?.fieldViolations?.[0]?.ruleName as string) ??
    (errorDataDetails?.validationError?.fieldViolations?.[0]?.description as string);

  return {
    code,
    data,
    type: isPaymentError(code) ? ErrorType.PAYMENT : ErrorType.GENERAL,
  };
}

function isGiftCardError(errorDataDetails: Details) {
  const giftCardCalculationError = errorDataDetails?.applicationError?.data?.giftCardCalculationError as Details;
  const errorDetails = giftCardCalculationError ?? errorDataDetails;
  return Boolean(
    GiftCardErrorCodes[errorDetails?.applicationError?.code as string] ??
      GiftCardErrorCodes[errorDetails?.validationError?.fieldViolations?.[0]?.description as string]
  );
}

function isMinimumOrderAmountError(errorDataDetails: Details) {
  return Boolean((errorDataDetails?.applicationError?.data?.orderValidationErrors as ApplicationError[])?.[0]?.code);
}

function isCouponError(errorDataDetails: Details) {
  const couponCalculationError = errorDataDetails?.applicationError?.data?.couponCalculationError as Details;
  const errorDetails = couponCalculationError ?? errorDataDetails;
  return Boolean(
    CouponErrorCodes[errorDetails?.validationError?.fieldViolations?.[0]?.ruleName as string] ??
      CouponErrorCodes[errorDetails?.applicationError?.code as string]
  );
}

export function getGiftCardError(details: Details) {
  const code =
    (details?.applicationError?.code as string) ??
    (details?.validationError?.fieldViolations?.[0]?.description as string);

  const data = details?.applicationError?.data as Record<string, any>;

  return {
    type: ErrorType.GIFT_CARD,
    code,
    data,
  };
}

export function getCouponError(details: Details) {
  const code = details?.validationError?.fieldViolations?.[0]?.ruleName ?? (details?.applicationError?.code as string);
  const data = details?.validationError?.fieldViolations?.[0] as Record<string, any>;
  return {
    type: ErrorType.COUPON,
    code,
    data,
  };
}

const isPaymentError = (code: string): boolean => !!PaymentErrorCode[code];
