import {DescriptionLine} from '@wix/ambassador-ecom-v1-checkout/types';

export class LineItemOptionModel {
  public value: string;
  public title: string;
  constructor({value, title}: {value: string; title: string}) {
    this.value = value;
    this.title = title;
  }

  public static convertToOptionsList(descriptionLines?: DescriptionLine[]): LineItemOptionModel[] {
    return (descriptionLines ?? [])
      .filter(
        (descriptionLine) =>
          !!(descriptionLine.colorInfo
            ? descriptionLine.colorInfo?.translated?.trim()
            : descriptionLine.plainText?.translated?.trim())
      )
      .map(
        (descriptionLine) =>
          new LineItemOptionModel({
            title: descriptionLine.name?.translated || '',
            value: descriptionLine.colorInfo
              ? descriptionLine.colorInfo.translated!
              : descriptionLine.plainText!.translated!,
          })
      );
  }
}
