import localeDatasetAdapter, {Country, getCountryByShortKey} from '@wix/wixstores-locale-dataset-adapter';
import {AddressModel} from '../models/Address.model';

export const isSubdivisionValidForCountry = (address?: AddressModel): boolean => {
  const country: Country = getCountryFromLocaleDataset(address?.country ?? '');

  if (!country?.subdivisions) {
    return true;
  }

  return isSubdivisionInCountry(country, address?.subdivision);
};

function isSubdivisionInCountry(country: Country, subdivisionKey: string = '') {
  const fullSubdivisionKey = localeDatasetAdapter.mapReplacedSubdivision(subdivisionKey, country.key);
  const shortSubdivisionKey = fullSubdivisionKey.substring(fullSubdivisionKey.indexOf('-') + 1);
  return !!country?.subdivisions?.list?.some((subdivision) => subdivision.key === shortSubdivisionKey);
}

export function getSubdivisionName(countryKey?: string, subdivisionKey?: string): string | undefined {
  if (!countryKey || !subdivisionKey) {
    return;
  }
  const key = localeDatasetAdapter.mapReplacedSubdivision(subdivisionKey, countryKey);
  const shortSubdivisionKey = key.substring(key.indexOf('-') + 1);
  return localeDatasetAdapter.getStateByKey(countryKey, shortSubdivisionKey)?.displayName;
}

export const getCountryFromLocaleDataset = (country: string): Country => {
  return getCountryByShortKey(country, {
    deprecationTimestamps: [],
  });
};
