import {BuyerInfo} from '@wix/ambassador-ecom-v1-checkout/types';

export class BuyerInfoModel {
  public email?: string;
  public contactId?: string;
  constructor(buyerInfo?: BuyerInfo) {
    this.email = buyerInfo?.email ?? undefined;
    this.contactId = buyerInfo?.contactId ?? undefined;
  }
}
