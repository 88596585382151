import {SubscriptionOptionInfo, SubscriptionFrequency} from '@wix/ambassador-ecom-v1-checkout/types';

export class SubscriptionModel {
  public name: string;
  public isAutoRenewal: boolean;
  public frequency: SubscriptionFrequency;
  public interval: number;
  public billingCycles: number;

  constructor({title, subscriptionSettings}: SubscriptionOptionInfo) {
    this.name = title?.translated ?? title?.original ?? '';
    this.isAutoRenewal = Boolean(subscriptionSettings?.autoRenewal);
    this.frequency = subscriptionSettings?.frequency ?? SubscriptionFrequency.UNDEFINED;
    this.interval = subscriptionSettings?.interval ?? 1;
    this.billingCycles = subscriptionSettings?.billingCycles ?? 0;
  }
}
