import {ShowMandatory} from '@wix/wixstores-graphql-schema-node';

export class ShowMandatoryModel {
  public show: boolean;
  public mandatory: boolean;
  constructor(showMandatory?: ShowMandatory | null) {
    this.show = !!showMandatory?.show;
    this.mandatory = !!showMandatory?.mandatory && /* istanbul ignore next: test forms */ this.show;
  }
}
