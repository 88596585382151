import {CalculationErrors} from '@wix/ambassador-ecom-v1-checkout/types';
import {CheckoutErrorModel, getCouponError, getGiftCardError} from './CheckoutError.model';
import {LineItemModel} from './LineItem.model';
import {CheckoutErrorCode, ErrorType} from '../utils/errors';

export class ErrorsModel {
  public hasCalculationErrors: boolean;
  public hasTaxSubdivisionError: boolean;
  public hasTaxZipCodeError: boolean;
  public hasShippingSubdivisionError: boolean;
  public hasShippingZipCodeError: boolean;
  public couponError?: CheckoutErrorModel;
  public giftCardError?: CheckoutErrorModel;
  public noItemsError?: CheckoutErrorModel;

  constructor(lineItems: LineItemModel[], calculationErrors?: CalculationErrors) {
    this.hasShippingSubdivisionError =
      calculationErrors?.carrierErrors?.errors?.some(({error}) =>
        error?.validationError?.fieldViolations?.some(({field}) => field === 'shippingDestination.subdivision')
      ) ?? false;

    this.hasTaxSubdivisionError =
      calculationErrors?.taxCalculationError?.validationError?.fieldViolations?.some(
        (violationObject) => violationObject.field === 'subdivision' && violationObject.description === 'MISSING'
      ) ?? false;

    this.hasShippingZipCodeError =
      calculationErrors?.carrierErrors?.errors?.some(({error}) =>
        error?.validationError?.fieldViolations?.some(({field}) => field === 'postalCode')
      ) ?? false;

    this.hasTaxZipCodeError =
      calculationErrors?.taxCalculationError?.validationError?.fieldViolations?.some(
        (violationObject) => violationObject.field === 'postalCode' && violationObject.description === 'MISSING'
      ) ?? false;

    this.couponError =
      calculationErrors?.couponCalculationError &&
      new CheckoutErrorModel(getCouponError(calculationErrors.couponCalculationError));

    this.giftCardError =
      calculationErrors?.giftCardCalculationError &&
      new CheckoutErrorModel(getGiftCardError(calculationErrors.giftCardCalculationError));

    const isCheckoutEmpty = lineItems.length === 0;
    const areAllItemsOutOfStock = lineItems.every((lineItem) => lineItem.quantity === 0);

    if (isCheckoutEmpty) {
      this.noItemsError = new CheckoutErrorModel({
        code: CheckoutErrorCode.EMPTY_CHECKOUT,
        type: ErrorType.GENERAL,
      });
    } else if (areAllItemsOutOfStock) {
      this.noItemsError = new CheckoutErrorModel({
        code: CheckoutErrorCode.FULL_OUT_OF_STOCK,
        type: ErrorType.GENERAL,
      });
    }

    this.hasCalculationErrors =
      !!calculationErrors?.taxCalculationError ||
      !!calculationErrors?.couponCalculationError ||
      !!calculationErrors?.giftCardCalculationError ||
      !!calculationErrors?.membershipError ||
      !!calculationErrors?.discountsCalculationError ||
      !!calculationErrors?.generalShippingCalculationError ||
      !!calculationErrors?.carrierErrors ||
      !!(
        calculationErrors?.orderValidationErrors?.length &&
        /* istanbul ignore next */ calculationErrors?.orderValidationErrors?.length > 0
      );
  }
}
