import {AdditionalFee} from '@wix/ambassador-ecom-v1-checkout/types';
import {PriceModel} from './Price.model';

export class AdditionalFeeModel {
  public code: string;
  public name: string;
  public priceBeforeTax: PriceModel;
  constructor(additionalFee: AdditionalFee) {
    this.code = additionalFee.code!;
    this.name = additionalFee.name!;
    this.priceBeforeTax = new PriceModel(additionalFee.priceBeforeTax);
  }
}
