import {AppliedDiscount, Coupon} from '@wix/ambassador-ecom-v1-checkout/types';
import {PriceModel} from './Price.model';

export class AppliedCouponModel {
  public couponType?: string;
  public price: PriceModel;
  public code: string;
  public id: string;
  public name: string;

  constructor(coupon: Coupon) {
    this.couponType = coupon.couponType;
    this.price = new PriceModel(coupon.amount);
    this.code = coupon.code!;
    this.id = coupon.id!;
    this.name = coupon.name!;
  }

  public static fromDTO(appliedDiscounts: AppliedDiscount[] = []): AppliedCouponModel | undefined {
    const coupon = appliedDiscounts.find((discount) => discount?.coupon)?.coupon;
    return coupon ? new AppliedCouponModel(coupon) : undefined;
  }
}
