/* istanbul ignore file */
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';

export const getMultilingualHeader = (flowAPI: ControllerFlowAPI, siteStore: SiteStore): Record<string, string> => {
  const {multilingual} = flowAPI.environment;
  if (multilingual?.isEnabled) {
    const currentLanguage = multilingual.siteLanguages.find(
      (lang) => lang.languageCode === multilingual.currentLanguage
    );
    if (currentLanguage) {
      return {
        'x-wix-linguist': `${currentLanguage.languageCode}|${
          currentLanguage.locale
        }|${currentLanguage.isPrimaryLanguage.toString()}|${siteStore.storeId}`,
      };
    }
  }
  return {};
};
